function DisplayBox({title, value, textColour}) {

    var calculated = value;
    var firstUnit = "";
    var secondUnit = "p";

    if (value > 100 || value < 100){
        calculated = value / 100;
        firstUnit = "£";
        secondUnit = "";
    }

    return (
        <div className='displayBox'>
          <p className='displayBoxText'>{title}</p>
          <p className={'displayBoxValue ' + textColour}>{firstUnit}{calculated.toFixed(2)}{secondUnit}</p>
        </div>
    )
}

export default DisplayBox;